<template>
  <div class="person-events-item" v-loading="loading" @click="clickHandler">
    <div class="item-date">
      <span v-if="sourceEventCreatedDate">{{ $filters.formatDateTime($filters.parseDate(sourceEventCreatedDate)) }}</span>
    </div>
    <div>
      <img
        :key="'person-image'"
        :src="sourceEventThumbnail"
        class="person-image-thumbnail"
        @click.stop="() => showImage(getEventFullframe(sourceEvent), getEventBbox(sourceEvent))"
      />
      <img
        :key="'person-image'"
        :src="targetEventThumbnail"
        class="person-image-thumbnail"
        @click.stop="() => showImage(getEventFullframe(targetEvent), getEventBbox(targetEvent))"
      />
    </div>
    <div class="item-camera">
      {{ cameraName }}
    </div>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  name: 'person-events-item',
  props: {
    items: {
      type: Array,
      required: true
    },
    useLastEvent: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      sourceEvent: {},
      targetEvent: {}
    };
  },
  computed: {
    targetEventThumbnail() {
      return (this.useLastEvent && this.targetEvent.episode_last_event_thumbnail) || this.targetEvent.thumbnail;
    },
    sourceEventThumbnail() {
      return (this.useLastEvent && this.sourceEvent.episode_last_event_thumbnail) || this.sourceEvent.thumbnail;
    },
    sourceEventCreatedDate() {
      return (this.useLastEvent && this.sourceEvent.episode_last_created_date) || this.sourceEvent.created_date;
    },
    cameraName() {
      const cameraId = this.sourceEvent.camera;

      if (cameraId) {
        const camera = this.$store.state.cameras.items.find((v) => v.id === cameraId),
          cameraGroup = this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || {};
        return cameraGroup.name + ' / ' + camera.name;
      } else {
        return cameraId || '';
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    getEventFullframe(v) {
      return (this.useLastEvent && v.episode_last_event_fullframe) || v.fullframe;
    },
    getEventBbox(v) {
      return (this.useLastEvent && v.episode_last_event_bbox) || v.bbox;
    },
    showImage(src, bbox) {
      const data = { src, faces_bbox: [this.$filters.rectLTRBToShortRect(bbox)] };
      this.$store.dispatch('showImage', data);
    },
    load() {
      this.loading = true;
      Promise.all([
        this.$store.dispatch('getBatchPersonEvents', { id_in: this.items[1] }),
        this.$store.dispatch('getBatchPersonEvents', { id_in: this.items[0] })
      ])
        .then(([source, target]) => {
          this.loading = false;
          this.sourceEvent = source;
          this.targetEvent = target;
        })
        .catch((e) => {
          console.warn('[connects:person-events] error loading ', this.items);
          this.loading = false;
        });
    },
    clickHandler() {
      const path = '#/person-events/filter/' + qs.stringify({ id_in: this.items, limit: 10 });
      window.open(path, '_blank');
    }
  }
};
</script>

<style lang="stylus">
.person-events-item {
  width: 14rem;
  min-height: 10rem;
  padding: 0.5rem;
  margin-bottom: 1 rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .item-date {
    padding-bottom: 0.5rem;
  }

  .item-camera {
    padding-top: 0.5rem;
  }

  .person-image-thumbnail {
    cursor: pointer;
    position: relative;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    top: 0;
    border-radius: 4px;
    transition: border 0.5s;
  }

}
</style>
