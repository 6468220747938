<template>
  <div class="cols h--100 clear-padding">
    <div class="relation-items flex-cell--static" v-infinite-scroll="() => append(-1)" v-loading="personsState.loading" infinite-scroll-delay="1000">
      <div class="content-header mar-left--1">
        <h2>{{ $tfo('relations') }}</h2>
      </div>
      <h3 class="text-center" v-if="personsState.loading">{{ $tf('loading') }}</h3>
      <h3 class="text-center" v-else-if="!personsState.loading && !personsState.items.length">{{ $tf('no_data') }}</h3>

      <relation-item :key="'key-' + item.id" :item="item" :current-item-state="state.levels[0]" v-for="item in personsState.items" />

      <h3 v-if="personsState.appending" class="text-center">{{ $tf('loading') + '...' }}</h3>
    </div>

    <template v-for="(level, index) in currentLevels">
      <div class="relation-items flex-cell--static person-events" :class="{ open: !!state.levels[index + 1].current }">
        <template v-if="state.levels[index + 1].current">
          <div class="content-header">
            <h2>{{ $tfo('events') }}</h2>
          </div>
          <person-events-item
            :key="'key-' + items.join('-')"
            :items="items"
            :use-last-event="state.levels[index + 1].filter.current.use_last_event"
            v-for="items in state.levels[index + 1].current.matched_person_events"
          />
        </template>
      </div>

      <div class="relation-items flex-cell--static" v-loading="level.loading" v-infinite-scroll="() => append(index)">
        <div class="content-header">
          <h2>{{ $tfo('circle') + ' ' + (index + 1) }}</h2>
        </div>
        <h3 class="text-center" v-if="level.loading">{{ $tf('loading') }}</h3>
        <h3 class="text-center" v-else-if="!level.loading && !level.items.length">{{ $tf('no_data') }}</h3>

        <relation-item :key="'relation-' + item.id" :item="item" :current-item-state="state.levels[index + 1]" v-for="item in level.items" />
        <h3 v-if="level.appending" class="text-center">{{ $tf('loading') + '...' }}</h3>
      </div>
    </template>
  </div>
</template>

<script>
import RelationItem from './relation.item';
import PersonEventsItem from './person.events.item';

export default {
  name: 'contacts',
  components: { PersonEventsItem, RelationItem },
  computed: {
    level0() {
      return this.$store.state.contacts.levels[0];
    },
    level1() {
      return this.$store.state.contacts.levels[1];
    },
    level2() {
      return this.$store.state.contacts.levels[2];
    },
    state() {
      return this.$store.state.contacts;
    },
    personsState() {
      return this.$store.state.persons;
    },
    currentLevels() {
      return this.state.levels.slice(0, this.state.levels.length - 1).filter((v) => v.current);
    }
  },
  watch: {
    'level0.current': function (v, p) {
      this.loadLevel(v, 0);
    },
    'level1.current': function (v, p) {
      this.loadLevel(v, 1);
    },
    'level2.current': function (v, p) {
      this.loadLevel(v, 2);
    },
    '$route.params.filter': function (v, p) {
      this.$store.dispatch('getPersonContacts', { id: null, level: -1 });
    }
  },
  mounted() {
    this.loadLevel({ id: null }, -1);
  },
  methods: {
    append(levelIndex) {
      if (levelIndex === -1) {
        if (this.personsState.filter.current.id) return;
        this.$store.dispatch('get_next_persons', { append: true });
      } else {
        this.$store.dispatch('getPersonContacts', {
          id: this.$store.state.contacts.levels[levelIndex].current.id,
          level: levelIndex,
          append: true
        });
      }
    },
    loadLevel(v, level) {
      if (v) {
        this.$store.dispatch('getPersonContacts', { id: v.id, level });
      } else {
        this.state.levels[level].items = [];
      }
    }
  }
};
</script>
